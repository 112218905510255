import { useProductList } from '@jetshop/core/hooks/ProductList';
import Image from '@jetshop/ui/Image';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import ProductLink from '@jetshop/ui/ProductLink';
import { styled } from 'linaria/react';
import React from 'react';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { theme } from '../Theme';
import { PreStyledPrice } from '../ui/PreStyledPrice';

const FavouriteProductWrapper = styled('div')`
  position: relative;
  display: flex;
  width: 100%;

  a {
    text-decoration: none;
    width: 100%;
    &:hover {
      text-decoration: none !important;
    }
  }

  /* &:first-child {
    border-top: 1px solid #dfdfdf;
  } */
`;

const CardImage = styled(Image)`
  [data-flight-image] {
    mix-blend-mode: multiply;
  }
`;

const CardContent = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding: 24px 14px;
  border-bottom: 1px solid #dfdfdf;

  > div:first-child {
    background: transparent;
    position: relative;
    width: 28%;
    overflow: hidden;
  }
`;

const CardInfo = styled('div')`
  flex: 1;
  position: relative;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding-right: 15px;

  h3,
  ul li {
    font-family: ${theme.fonts.primary};
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: black;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info {
    display: flex;
    flex-direction: column;
  }
`;

const TopRow = styled('div')`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NameAndVariantWrapper = styled('div')`
  margin-top: 7px;
  font-size: 12px;
  font-weight: 400;
  min-width: 70%;
  a,
  p {
    color: black;
  }
  ${theme.below.lg} {
    margin-top: 0;
  }
`;

export const RemoveItem = styled('div')`
  margin-left: 20px;
  button {
    outline: none;
    border: none;
    background: #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
    font-size: 13px;
  }
  svg {
    height: 10px;
    width: 10px;
    color: white;
  }
`;

const BottomRow = styled('div')`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;

  > * {
    flex: 1;
  }

  [data-flight-dropdown] {
    button {
      width: 100%;
      font-family: ${theme.fonts.primary};
      font-size: 10px;
      line-height: 10px;
      padding: 0.5em 0.5em;
      text-align: left;
      background: white;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          height: 8px;
          width: 8px;
          fill: black;
        }
      }
    }
  }

  [data-flight-dropdown-items] {
    width: 100%;
    border: 1px solid #c0c0c0;
    border-top: none;

    li:first-child {
      border-top: none;
    }

    li {
      font-family: ${theme.fonts.primary};
      font-size: 10px;
      line-height: 10px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;

      svg {
        height: 10px;
        width: 10px;
        path {
          fill: black;
        }
      }
    }
  }

  .add-button {
    width: 100%;
    font-family: ${theme.fonts.primary};
    font-size: 10px;
    line-height: 10px;
    padding: 0.5em 0.5em;
    text-align: center;
    background: black;
    color: white;
  }
`;

const LinePrice = styled(PreStyledPrice)`
  margin-top: 0.5rem;
  font-weight: 600;
  text-align: right;
  align-items: center;
  display: flex;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  color: ${theme.colors.darkgrey};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.05em;

  .new-price {
    color: ${theme.colors.gold};
    margin-right: 4px;
  }
`;

const PriceWrapper = styled('div')`
  display: flex;
  align-items: baseline;
`;

const Goto = styled('div')`
  display: flex;
  justify-content: flex-end;
  a {
    width: unset;
  }
  svg {
    cursor: pointer;
    height: 14px;
    width: 14px;
    fill: black;
    stroke: black;
    transform: rotate(-90deg);
  }
`;

export const FavouriteItemCard = ({ product }) => {
  return (
    <FavouriteProductWrapper>
      <FlyoutTrigger id="favourites-drawer">
        {flyout => (
          <CardContent>
            <div className={'image-wrapper'}>
              {product?.images?.length > 0 && (
                <CardImage
                  aspect="1:1"
                  sizes="5rem"
                  crop
                  src={product?.images[0]?.url}
                  alt={product?.images[0]?.alt}
                />
              )}
            </div>
            <CardInfo>
              <TopRow>
                <div className={'info'}>
                  <NameAndVariantWrapper>
                    <ProductLink product={product} onClick={flyout.hideTarget}>
                      <h3>
                        <strong>{product?.subName}</strong>
                      </h3>
                      <h3>{product?.name}</h3>
                    </ProductLink>
                  </NameAndVariantWrapper>
                </div>
                <RemoveFromList
                  articleNumber={product.articleNumber}
                  variant={product.variant}
                />
              </TopRow>
              <BottomRow>
                <PriceWrapper>
                  <div>
                    <LinePrice
                      data-testid="item-price"
                      price={product?.price}
                      previousPrice={product?.previousPrice}
                    />
                  </div>
                </PriceWrapper>
                <Goto>
                  <ProductLink product={product} onClick={flyout.hideTarget}>
                    <Caret />
                  </ProductLink>
                </Goto>
              </BottomRow>
            </CardInfo>
          </CardContent>
        )}
      </FlyoutTrigger>
    </FavouriteProductWrapper>
  );
};

function RemoveFromList({ articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <RemoveItem>
      <button onClick={() => remove(articleNumber, { variantArticleNumber })}>
        <Cross />
      </button>
    </RemoveItem>
  );
}
