import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useEffect } from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import { FavouriteItemCard } from './FavouriteItemCard';
import { Share } from './Share';
import useHeaderHeight from '../../hooks/useHeaderHeight';

const DrawerWrapper = styled('div')`
  // Flyout
  position: relative;
  > div {
    background: #f7f7f7;
    top: ${props => props.topPosition}px;
  }
`;

const Flyout = styled('div')`
  background: white;
  color: ${theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  .favourite-upper {
    width: 100%;

    .add-clear {
      padding: 1rem;
    }
  }

  > div {
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  > section {
    ${theme.below.sm} {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`;

const EmptyContainer = styled('div')`
  padding: 0 1.2rem;
  margin-top: 2em;

  h1 {
    font-size: x-large;
  }

  p {
    font-size: small;
  }
`;

const AddAllToCartButton = styled('a')`
  width: 100%;
  display: flex;
  color: white !important;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: normal;
  margin-top: 1rem;
  cursor: pointer;
  background: ${theme.colors.gold};
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  font-weight: 600;

  &:hover,
  &:active {
    background: #9e7d47;
    text-decoration: none !important;
  }
`;

const ClearAllFavouritesButton = styled(AddAllToCartButton)`
  background: transparent;
  margin-top: 10px;
  text-decoration: none;
  color: black !important;

  &:hover,
  &:active {
    text-decoration: underline !important;
  }
`;

const FavouriteItems = styled('section')`
  background: white;
`;

const container = css`
  padding: 0;

  &.loading {
    opacity: 0.6;
  }

  h1 {
    margin-bottom: 1em;
    font-weight: 600;
  }

  .product-grid {
    display: block;
    width: 100%;

    li {
      width: 100%;
      position: relative;

      .product-card {
        padding: 1rem;
        border-bottom: 1px solid #e6e6e6;

        a {
          margin: 0 !important;
          border: 0;
          width: 100%;
          display: flex;

          .product-image {
            flex: 1;
            display: flex;
            flex: none;
            width: 60px;
            border: 1px solid #f7f7f7;
            box-shadow: 0px 3px 10px 3px #f7f7f7;
          }

          .product-card-detail {
            flex: 2;
            padding-top: 25px;
            padding-left: 20px;

            h3 {
              font-weight: normal;
              font-size: 1rem;
              max-width: 70%;
            }

            .sub-name {
              display: none;
            }

            .price-package-wrapper {
              div {
                font-size: 14px;
              }
            }
          }
        }

        button {
          background: none;
          border-radius: 0;
          border: 0;
          // left: 80px;
          left: -10px;
          top: -40px;

          svg path {
            fill: ${theme.colors.black};
          }
        }
      }
    }
  }

  .add-button {
    display: none;
  }

  .select-variant {
    display: none;

    margin: 0;
    padding: 1em;
    position: relative;
    z-index: 999;

    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }

      button,
      ul,
      li {
        width: 100%;
      }

      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .add-clear {
    display: none;
  }

  .add-clear button {
    max-width: 30ch;
    display: block;
    margin: 0.5em auto;
  }

  .new-price,
  .old-price {
    display: inline-block;
  }

  .old-price {
    margin-left: 0.5em;
  }
`;

let favouriteCount = 0;

function FavouritesFlyoutView({ modal, ...rest }) {
  const { products, loading } = useProductListItems();
  const { clear } = useProductList();

  useEffect(() => {
    // THIS IS SO THAT FINDIFY KNOWS WHEN TO CHECK AGAIN FOR FAVOURITES
    // IN THEIR CURRENTLY SHOWN CATEGORY LIST
    if (products && products.length !== favouriteCount) {
      if (products.length < favouriteCount) {
        const favouriteRemovedEvent = new Event('favouriteRemoved');
        window.dispatchEvent(favouriteRemovedEvent);
      }
      favouriteCount = products.length;
    }
  });

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  return (
    <>
      <DrawerTrigger preventOverflow={true} id="favourites-drawer">
        {drawer => {
          if (noProductsInList) {
            return (
              <Flyout {...rest}>
                <MaxWidth className={cx(container, loading ? 'loading' : null)}>
                  <EmptyContainer>
                    <h2>{t('Saved items')}</h2>
                    <p>
                      {t('There are no products in your saved items list.')}
                    </p>
                    <Share drawer={drawer} />
                  </EmptyContainer>
                </MaxWidth>
              </Flyout>
            );
          }

          if (loadingInitialRender) {
            return (
              <Flyout {...rest}>
                <MaxWidth className={cx(container, loading ? 'loading' : null)}>
                  <h2>{t('Saved items')}</h2>
                  <p>{t('Loading your saved items…')}</p>
                </MaxWidth>
              </Flyout>
            );
          }

          return (
            <Flyout {...rest}>
              <div className={'favourite-upper'}>
                <FavouriteItems>
                  {products.map((product, index) => {
                    return <FavouriteItemCard key={index} product={product} />;
                  })}
                </FavouriteItems>
                {products.length > 0 && (
                  <div className={'add-clear'}>
                    <ClearAllFavouritesButton
                      onClick={() => {
                        clear();
                      }}
                    >
                      {t('Clear favourites')}
                    </ClearAllFavouritesButton>

                    <Share drawer={drawer} />
                  </div>
                )}
              </div>
            </Flyout>
          );
        }}
      </DrawerTrigger>
    </>
  );
}

const CartFlyout = props => {
  const topPosition = useHeaderHeight();
  return (
    <DrawerTarget id="favourites-drawer">
      {drawer => (
        <DrawerWrapper topPosition={topPosition}>
          <Drawer isOpen={drawer.isOpen} right>
            <FavouritesFlyoutView modal={drawer} {...props} />
          </Drawer>
        </DrawerWrapper>
      )}
    </DrawerTarget>
  );
};

export default CartFlyout;
